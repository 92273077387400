<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvPasswordForm></OvPasswordForm>

	2020-11-23	init

-->

<template>
	<div class="OvPasswordForm contentModule">
		<form class="OvPasswordForm__inner contentModule__inner hSpace hSpace--app maxWidth maxWidth--app" v-on:submit.prevent autocomplete="off">
			<div class="OvPasswordForm__row">
				<p class="OvPasswordForm__text richText">
					Diese Seite ist durch ein Passwort geschützt.
				</p>
			</div>
			<div class="OvPasswordForm__row">
				<input
					class="OvPasswordForm__passwordInput"
					id="OvPasswordForm__input"
					name="OvPasswordForm"
					type="password"
					v-model="password"
					placeholder="Passwort"
					autocomplete="new-password"
					v-on:keyup.enter="onCheckAndSubmitForm"
				/>
				<!--
				<input
					class="OvPasswordForm__submitButton"
					type="submit"
					value="Absenden"
					@click="onCheckAndSubmitForm"
				/>
				-->
				<OvLabel2
					class="OvPasswordForm__submitButton font font--sizeDefault"
					text="Absenden"
					bgColor="white"
					textColor="black"
					role="button"
					@click.native="onCheckAndSubmitForm"
					:forceShadow="true"
				></OvLabel2>
				<MhSpinner
					class="OvPasswordForm__spinner"
					v-if="showSpinner"
				></MhSpinner>
			</div>

			<!--
			-->
		</form>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import OvLabel2 from '@/components/OvLabel2.vue'
	import MhSpinner from '@/components/MhSpinner/MhSpinner.vue'

	export default {
		name: 'OvPasswordForm',
		components: {
			OvLabel2,
			MhSpinner,
		},
		mixins: [],
		props: {
			showSpinner: {
				type: [Boolean],
				default: false,
			},
			/*
			text: {
				type: [String, Boolean],
				default: '',
			},
			*/
		},
		data() {
			return {
				password : '',
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			doClearPassword(){
				console.log('doClearPassword')
				this.password = ''
			},
			onSubmitForm( e ){
				e.preventDefault
			},
			onCheckAndSubmitForm(){
				const password = this.password
				//console.log('onCheckAndSubmitForm', password)

				if( password ) this.$emit('submitPassword', password)
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvPasswordForm {
		//background-color: fade( red, 10 );
		padding: 1em 0;

		&__row {
			display: flex;
			align-items: center;
		}
		&__text {
			padding-bottom: 1em;
		}
		&__passwordInput {
			height: 1.35em;
			margin-right: 0.25em;

			font: inherit;
			text-indent: 0.25em;
		}
		&__submitButton {
			cursor: pointer;
			margin-right: 0.25em;
		}
		&__spinner {
			stroke-width: 4px;
			color: var(--color-primary-shadow);
			circle { stroke-opacity: 0.5; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
