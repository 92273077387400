<template>
	<MhRouterView class="OvPageView view background2--color-primary-background" :isContentReady="isContentReady">

		<!-- default case -->
		<div class="minHeight minHeight--100 vSpace vSpace--viewContent" v-if="!is404">

			<OvMainHeadline
				:post="post"
			></OvMainHeadline>

			<OvSpacer type="default" :rows="6"></OvSpacer>

			<OvPasswordForm
				ref="OvPasswordForm"
				v-if="isPasswordProtected"
				:showSpinner="showPasswordFormSpinner"
				@submitPassword="onSubmitPassword"
			></OvPasswordForm>
			<OvContentModules :post="post"></OvContentModules>

		</div>

		<!-- 404 case -->
		<div class="minHeight minHeight--100 vSpace vSpace--viewContent" v-else>

			<Ov404></Ov404>

		</div>

		<OvMainFooter></OvMainFooter>

		<!--
		<pre data-name="post">{{post}}</pre>
		-->

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	import MhRouterView from '@/components/MhRouterView/MhRouterView.vue'
	import OvMainHeader from '@/components/OvMainHeader.vue'
	import OvMainHeadline from '@/components/OvMainHeadline.vue'
	import OvPasswordForm from '@/components/OvPasswordForm.vue'
	import OvContentModules from '@/components/OvContentModules.vue'
	import OvMainFooter from '@/components/OvMainFooter.vue'
	import OvSpacer from '@/components/OvSpacer.vue'
	import Ov404 from '@/components/Ov404.vue'

	export default {
		name: 'OvPageView',
		components: {
			MhRouterView,
			OvMainHeader,
			OvMainHeadline,
			OvPasswordForm,
			OvContentModules,
			OvMainFooter,
			OvSpacer,
			Ov404,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {},
		data() {
			return {
				post : {},
				isContentReady : false,
				is404 : false,

				postPassword : '',
				showPasswordFormSpinner : false,
			}
		},
		watch: {
			restHandler__pendingRequests: {
				handler( to, from, doLog = false ) {
					const icon = to ? '❌' : '✅'

					if( doLog ){
						console.groupCollapsed(this.$options.name, '• watch restHandler__pendingRequests', from, to, icon)
						console.groupEnd()
					}

					if( !to ) this.isContentReady = true
				},
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			isPasswordProtected(){
				return this._.get( this.post, 'content.protected', false )
			},
		},
		methods: {
			onSubmitPassword( password ){
				//console.log('onSubmitPassword', password)

				this.postPassword = password
				this.showPasswordFormSpinner = true

				this.fetchPost(()=>{
					this.$refs.OvPasswordForm.doClearPassword()
					this.showPasswordFormSpinner = false
				})
			},
			fetchPost( callback = ()=>{}, doLog = true ){

				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						slug : this.$route.params.slug,
						password : this.postPassword,
					},
					callback : (response) => {
						const result = response.data.result[0]

						this.post = result
						this.is404 = result ? false : true

						if( doLog ){
							console.groupCollapsed('OvPageView • fetchPost() callback')
							console.log('result:', result)
							console.groupEnd()
						}

						callback()
					},
				})

			},
		},
		mounted() {
			this.fetchPost(()=>{
				this.isContentReady = true
			})
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";

	.OvPageView {
		//background-color: yellow;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
